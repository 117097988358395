import React, { useCallback, useEffect, useRef } from "react";
import { useGalleryContext } from "../../Context/GalleryContext";
import { FaAngleDown } from "react-icons/fa";
import styled from "styled-components";

const CategoryBtns = () => {
  const {
    height,
    btnContainer,
    setBtnContainer,
    newCategories,
    setHeight,
    filterArr,
  } = useGalleryContext();
  const categoryBtn = useCallback((node) => {
    if (node !== null || node !== undefined) {
      setHeight(node?.clientHeight);
    }
  }, []);
  return (
    <Wrapper>
      <div
        className="btn-container"
        onClick={() => {
          setBtnContainer(!btnContainer);
        }}
      >
        <div className="_text">
          <button className="category-dropdown">Category</button>
          <FaAngleDown
            className={!btnContainer ? "angle" : "angle opposite-angle"}
          />
        </div>
        <div
          className={
            btnContainer ? "category-buttons" : "category-buttons active"
          }
          style={{
            height: `${
              btnContainer ? ` ${height * newCategories.length}px` : 0
            }`,
          }}
        >
          {newCategories.map((category, i) => {
            return (
              <li className="category-btn-container" key={i} ref={categoryBtn}>
                <button
                  className="category-btn"
                  onClick={() => filterArr(category)}
                >
                  {category}
                </button>
              </li>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .btn-container {
    display: grid;
    grid-template-rows: 1fr auto;
    height: fit-content;
    overflow-y: hidden;
    max-width: 400px;
    margin: 1.5rem auto;
    .category-btn-container {
      width: 70%;
      margin: 0 auto;
      @media screen and (min-width: 868px) {
        width: 95%;
      }
    }
    .category-btn,
    ._text {
      padding: 0.5rem;
      width: 100%;
      height: fit-content;
      background-color: ${(props) => props.theme.colors.secondaryDarkBg};
      font-size: 1.2rem;
      border-radius: ${(props) => props.theme.borderRadiusEdges};
      width: 100%;
      margin: 0 auto;
      margin-top: 0.5rem;
    }
    button {
      background: none;
      color: ${(props) => props.theme.colors.Yellow};
    }
    ._text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      font-size: 1.5rem;
      background-color: ${(props) => props.theme.colors.DarkGray};
      .angle {
        color: white;
        transition: ${(props) => props.theme.transition};
      }
      .opposite-angle {
        transform: rotate(-180deg);
      }
    }
    .category-buttons {
      margin: 0 auto;
      width: 100%;
      display: grid;
      overflow: hidden !important;
      transition: ${(props) => props.theme.transition};
      position: relative;
    }
    .active {
      height: 0;
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
`;
export default CategoryBtns;

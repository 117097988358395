import React, { useContext } from "react";
import useFetch from "../Custom_Hooks/UseFetch";

const CommissionsContext = React.createContext();
const CommissionsProvider = ({ children }) => {
  const { Response, loading, setLoading } = useFetch({
    key: process.env.REACT_APP_COMMISSIONS_KEY,
    action: process.env.REACT_APP_COMMISSIONS_ACTION,
  });
  const Data = [...Response]
  return (
    <CommissionsContext.Provider value={{ Data, loading, setLoading }}>
      {children}
    </CommissionsContext.Provider>
  );
};

const useCommissionsContext = () => {
  return useContext(CommissionsContext);
};

export { CommissionsProvider, useCommissionsContext };
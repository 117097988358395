import React, { useContext } from "react";
import useFetch from "../Custom_Hooks/UseFetch";

const TOSContext = React.createContext();
const TOSProvider = ({ children }) => {
  const { Response, loading, setLoading } = useFetch({
    key: process.env.REACT_APP_TOS_KEY,
    action: process.env.REACT_APP_TOS_ACTION,
  });
  const Data = Response.filter((data) => data);
  return (
    <TOSContext.Provider value={{ Data, loading, setLoading }}>
      {children}
    </TOSContext.Provider>
  );
};

const useTOSContext = () => {
  return useContext(TOSContext);
};

export { TOSProvider, useTOSContext };
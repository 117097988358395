import React from "react";
import styled from "styled-components";
import CategoryBtns from "../components/Btns/CategoryBtns";
import Btns from "../components/Btns/pagination_btns";
import GalleryComponent from "../components/Gallery/GalleryComponent";
import Header from "../components/Header/Header";
import { GalleryProvider } from "../Context/GalleryContext";

const All_Artworks = () => {
  return (
    <GalleryProvider>
      <Wrapper className="gallery-page">
        <div className="container">
          <Header theme={"Gallery"} />
          <div className="image-category-container">
            <CategoryBtns />
            <article className="article gallery-article">
              <GalleryComponent />
            </article>
          </div>
          <Btns />
        </div>
      </Wrapper>
    </GalleryProvider>
  );
};
const Wrapper = styled.article`
  .container {
    grid-template-rows: auto 1fr auto;
    .image-category-container {
      display: grid;
      grid-template-rows: auto 1fr;

      @media screen and (min-width: 1200px) {
        grid-template-columns: 40% 1fr;
      }
    }
  }
`;

export default All_Artworks;

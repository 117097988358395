import React from "react";
import { useAboutContext } from "../../Context/AboutContext";
import Typewriter from "typewriter-effect";
import LoadingComponent from "../loading_component/loading";
import styled from "styled-components";
const AboutComponent = () => {
  const { Data, loading } = useAboutContext();
  return (
    <Wrapper>
      {!loading ? (
        Data.map((item, i) => {
          return (
            <div key={i}>
              <h2 className="AnimatedText">
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter.typeString(item.Intro).start();
                  }}
                />
              </h2>
              <p>{item.Text}</p>
            </div>
          );
        })
      ) : (
        <LoadingComponent />
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .AnimatedText {
    font-weight: bold;
    color: transparent;
    background: linear-gradient(
      45deg,
      ${(props) => props.theme.colors.Yellow},
      ${(props) => props.theme.colors.White}
    );
    background-clip: text;
    -webkit-background-clip: text;
  }
`;
export default AboutComponent;

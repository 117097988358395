export const theme = {
  colors: {
    black: "#1C1B19",
    LightGray: "#707070",

    BtnGray: "#7C786D",
    DarkGray: "#161515f1",
    White: "#F7F6F4",
    Yellow: "#F1ECCE",
  },
  shadows: {
    lightShadow: " 0 5px 15px rgba(0, 0, 0, 0.1)",
    darkShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
  },
  text: {
    fonts: {
      smallScreen: "1.1rem",
      normalScreen: "1.3rem",
    },
    arrows: "2.5rem",
  },
  widths: {
    maxWidth: "1500px",
    fixedWidth: "868px",
    cardWidth: "450px",
  },
  transition: "all 0.3s linear",
  borderRadiusEdgesSmall: "0.5rem",
  borderRadiusEdges: "2rem",
  letterSpacing: "0.1rem",
};

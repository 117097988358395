import React from "react";
import { IoLogoVenmo } from "react-icons/io5";
import { SiCashapp, SiPaypal } from "react-icons/si";
import styled from "styled-components";

const Payments = () => {
  return (
    <Wrapper>
      <h4 className="payments-text">Payments</h4>
      <div>
        <a href="https://cash.app/$IvaLewis2022">
          <SiCashapp className="cashapp" />
        </a>
        <a href="https://paypal.me/akashjl?country.x=US&locale.x=en_US">
          <SiPaypal className="paypal" />
        </a>
        <a href="https://www.venmo.com/u/AkashJL">
          <IoLogoVenmo className="venmo" />
        </a>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section `
    & {
    display: flex;
    max-width: 250px;
    gap: 2em;
    background-color: #222222;
    padding: 0.5rem;
    border-radius: ${(props) => props.theme.borderRadiusEdges};
    place-items: center;
    max-width: ${(props) => props.theme.widths.cardWidth};
    margin: 0 auto;
    font-size: 2rem;
    height: 100%;
    div {
      display: flex;
      align-items: content;
      a {
        align-items: center;
        display: flex;
        height: 100%;
      }
    }
    .cashapp {
      color: #00ff00;
    }
    .paypal {
      color: #2212b4;
    }
    .venmo {
      filter: brightness(150%);
    }
  }
`
export default Payments;

import React, { useContext } from "react";
import useFetch from "../Custom_Hooks/UseFetch";

const FeaturedContext = React.createContext();
const FeaturedProvider = ({ children }) => {
  const { Response, loading, setLoading } = useFetch({
    key: process.env.REACT_APP_FEATURED_KEY,
    action: process.env.REACT_APP_FEATURED_ACTION,
  });
  const Data = Response.filter((data) => data.ID && data.Category);
  return (
    <FeaturedContext.Provider value={{ Data, loading, setLoading }}>
      {children}
    </FeaturedContext.Provider>
  );
};

const useFeaturedContext = () => {
  return useContext(FeaturedContext);
};

export { FeaturedProvider, useFeaturedContext };
import { useEffect, useState } from "react";
const useFetch = ({ key, action }) => {
  const url = `https://script.google.com/macros/s/${key}/exec?action=${action}`;
  const [Response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((response) => {
        setResponse(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        throw new Error(`Error fetching url: ${url}`);
      });
  }, []);
  return { Response, loading, setLoading };
};
export default useFetch;

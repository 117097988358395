import React, { useState } from "react";
import Logo1x from "../../Logo/Omaya logo white@2x.png";
import LogoS from "../../Logo/Omaya logo white.png";
import { FaBars } from "react-icons/fa";
import styled from "styled-components";
import { Arr } from "../../data/icons/icons";
const Navbar = ({ Link }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <>
      <Wrapper className="navbar-main">
        <div className="img-container-logo">
          <Link to="/">
            <picture>
              <source
                srcSet={LogoS}
                media="(max-width: 360px)"
                className="logo"
              />
              <img src={Logo1x} alt="MDN" className="logo" />
            </picture>
          </Link>
        </div>
        <FaBars className="FaBars" onClick={() => setToggle(!toggle)} />
        <ul className="navbar-list__container">
          {Arr.map(({ Icon, Title, href }, i) => {
            return (
              <ul key={i}>
                {/* <img src={Icon} alt="" className="icon" /> */}
                <Link
                  className="title"
                  to={href}
                  onClick={() => setToggle(false)}
                >
                  <li>{Title}</li>
                </Link>
              </ul>
            );
          })}
        </ul>
      </Wrapper>
      {toggle ? (
        <Modal>
          <div className="modal-list">
            {Arr.map(({ Icon, Title, href }, i) => {
              return (
                <ul className="modal-list__container" key={i}>
                  <img src={Icon} alt="" className="icon" />
                  <Link
                    className="title"
                    to={href}
                    onClick={() => setToggle(!toggle)}
                  >
                    <li>{Title}</li>
                  </Link>
                </ul>
              );
            })}
          </div>
        </Modal>
      ) : (
        <Modal className="modal-closed"></Modal>
      )}
    </>
  );
};
const Wrapper = styled.nav`
  display: flex;
  grid-template-columns: auto auto;
  justify-content: space-between;

  align-items: center;
  height: 8vh;
  padding: 0 0.5rem;
  z-index: 10000;
  .navbar-list__container {
    display: none;
    gap: 1rem;
    @media screen and (min-width: 868px) {
      display: flex;
    }
    a {
      text-decoration: none;
    }
  }
  @include for-size(desktop-up) {
    nav {
      height: 12vh;
    }
  }
  .img-container-logo {
    .logo {
      width: 100%;
    }
  }
  @supports (backdrop-filter: blur(20px)) {
    & {
      background: #0404043c;
      backdrop-filter: blur(5px);
    }
  }
  @supports not (backdrop-filter: none) {
    &:after {
      background: #000000b6;
    }
  }
  div {
    height: 100%;
  }
  .logo {
    max-width: 100%;
    max-height: 100%;
  }
  .FaBars {
    height: 100%;
    width: 2rem;
  }
`;
const Modal = styled.div`
  transition: ${(props) => props.theme.transition};
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
  display: grid;

  .modal-list {
    display: grid;
    place-content: center;
    gap: 1.5rem;
    &__container {
      display: flex;
      align-items: center;
      .icon {
        width: 1.7rem;
        height: 100%;
        margin-right: 1.5rem;
      }
      .title {
        text-decoration: none;
      }
      li {
        color: ${(props) => props.theme.colors.White};
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
  }
  @supports (backdrop-filter: blur(20px)) {
    & {
      background: #040404bb;
      backdrop-filter: blur(20px);
    }
  }
  @supports not (backdrop-filter: none) {
    &:after {
      background: #000000e6;
    }
  }
  &.modal-closed {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
`;
export default Navbar;

import React from "react";
import styled from "styled-components";
import Header from "../components/Header/Header";
import TOSComponent from "../components/TOS/TOSComponent";
import { TOSProvider } from "../Context/TOSContext";

const TOS = () => {
  return (
    <TOSProvider>
      <Wrapper className="page">
        <div className="container">
          <Header theme={"Terms of Service"} />
          <TOSComponent />
        </div>
      </Wrapper>
    </TOSProvider>
  );
};
const Wrapper = styled.article``;
export default TOS;

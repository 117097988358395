import React from "react";
import Wave from "react-wavify";
import styled from "styled-components";
import Social_Media from "../../data/icons/Social_Media";
const Footer = () => {
  return (
    <Wrapper>
      <div className="container">
        <div className="icons">
          <Social_Media />
        </div>
        <div className="text segoe-font">
          <p>&copy; all rights reserved {new Date().getFullYear()}</p>
        </div>
      </div>
      <Wave
        className="wave"
        fill="url(#gradient)"
        options={{ points: 3, speed: 0.15, amplitude: 20 }}
      >
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor="#F9EA8F" />
            <stop offset="95%" stopColor="#AFF1DA" />
          </linearGradient>
        </defs>
      </Wave>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  height: 15vh;
  .wave {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 20vh;
  }
  .container {
    display: grid;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10vh;
    align-items: flex-end;
    justify-items: center;

    .text {
      p {
        color: ${(props) => props.theme.colors.black};
        text-transform: capitalize;
        font-size: 1.3rem;
      }
    }
  }
`;
export default Footer;

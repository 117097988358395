import React, { useContext, useEffect, useRef, useState } from "react";
import useFetch from "../Custom_Hooks/UseFetch";

const GalleryContext = React.createContext();
const GalleryProvider = ({ children }) => {
  const { Response, loading } = useFetch({
    key: process.env.REACT_APP_GALLERY_KEY,
    action: process.env.REACT_APP_GALLERY_ACTION,
  });
  const Data = Response.filter((item) => item.ID && item.Category);
  const [tempArr, setTempArr] = useState([]);
  const [height, setHeight] = useState(0);
  const [btnContainer, setBtnContainer] = useState(false);
  const [Page, setPage] = useState(1);
  const [PostPerPage, setPostPerPage] = useState(9);
  const indexOfLastPage = Page * PostPerPage;
  const indexOfFirstPage = indexOfLastPage - PostPerPage;
  const currentImages = tempArr.slice(indexOfFirstPage, indexOfLastPage);
  const paginate = (num) => {
    setPage(num);
    window.scrollTo(0, 0);
  };

  const categoryBtn = useRef();
  const newCategories = ["All", ...new Set(Data.map((item) => item.Category))];
  const filterArr = (category) => {
    if (category === "All") {
      setTempArr([]);
      setPage(1);
      return setTempArr(Data);
    }
    const newArr = Data.filter((item) => item.Category === `${category}`);
    setTempArr([]);
    setPage(1);
    return setTempArr(newArr);
  };

  useEffect(() => {
    setTempArr(Response.filter((item) => item.ID && item.Category));
  }, [Response]);
  return (
    <GalleryContext.Provider
      value={{
        Data,
        tempArr,
        loading,
        setTempArr,
        height,
        btnContainer,
        setBtnContainer,
        setHeight,
        newCategories,
        filterArr,
        categoryBtn,
        currentImages,
        PostPerPage,
        paginate,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};

const useGalleryContext = () => {
  return useContext(GalleryContext);
};

export { GalleryProvider, useGalleryContext };

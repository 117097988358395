import React, { useContext } from "react";
import useFetch from "../Custom_Hooks/UseFetch";

const AboutContext = React.createContext();
const AboutProvider = ({ children }) => {
  const { Response, loading, setLoading } = useFetch({
    key: process.env.REACT_APP_ABOUT_TEXT_KEY,
    action: process.env.REACT_APP_ABOUT_TEXT_ACTION,
  });
  const Data = Response.filter((data) => data);
  return (
    <AboutContext.Provider value={{ Data, loading, setLoading }}>
      {children}
    </AboutContext.Provider>
  );
};

const useAboutContext = () => {
  return useContext(AboutContext);
};

export { AboutProvider, useAboutContext };
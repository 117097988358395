import React from "react";
import styled from "styled-components";
import Header from "../components/Header/Header";
import HomeFeaturedImage from "../components/HomePage/HomeFeaturedImage";
import { FeaturedProvider } from "../Context/HomepageFeaturedContext";
const Home = () => {
  return (
    <FeaturedProvider>
      <Wrapper className="page">
        <div className="container">
          <Header theme={"Featured"} />
          <article className="article">
            <HomeFeaturedImage />
          </article>
        </div>
      </Wrapper>
    </FeaturedProvider>
  );
};
const Wrapper = styled.main`
  .article {
    place-content: center;
  }
  
`;
export default Home;

import React from "react";
import styled from "styled-components";
import { useCommissionsContext } from "../../Context/CommissionsContext";
import ArticleHeader from "../Header/ArticleHeader";
import LoadingComponent from "../loading_component/loading";
import Payments from "../Payments/Payments";

const CommissionsComponent = () => {
  const { Data, loading } = useCommissionsContext();
  return (
    <CommissionsWrapper
      className={`${loading ? "article loading" : "article"}`}
    >
      <div className="commissions-article__container">
        {!loading ? (
          Data.map((item, i) => {
            return (
              <div key={i}>
                <ArticleHeader theme={item.Category} />
                <li className="article-link">{item.Cheaper}</li>
                <li className="article-link">{item.Expensive}</li>
              </div>
            );
          })
        ) : (
          <LoadingComponent />
        )}
      </div>
      <Payments/>
    </CommissionsWrapper>
  );
};
const CommissionsWrapper = styled.article`
  &.loading {
    place-content: center;
  }
`;
export default CommissionsComponent;

import React from "react";
import styled from "styled-components";
import CommissionsComponent from "../components/Commissions/CommissionsComponent";
import Header from "../components/Header/Header";
import { CommissionsProvider } from "../Context/CommissionsContext";

const Commissions = () => {
  return (
    <CommissionsProvider>
      <Wrapper className="page">
        <div className="container">
          <Header theme={"Commissions"} />
          <CommissionsComponent/>
        </div>
      </Wrapper>
    </CommissionsProvider>
  );
};
const Wrapper = styled.article`

`;

export default Commissions;

import styled from "styled-components";

const Header = ({ theme }) => {
  return (
    <Wrapper className="section">
      <div className="text-container">
        <h2 className="text">{theme}</h2>
        <div className="underline"></div>
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  .text-container {
    background: ${(props) => props.theme.colors.DarkGray};
    width: fit-content;
    padding: 0.8rem;
    border-radius: ${(props) => props.theme.borderRadiusEdgesSmall};
  }
`;
export default Header;

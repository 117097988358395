import React from "react";
import styled from "styled-components";
import { useTOSContext } from "../../Context/TOSContext";
import ArticleHeader from "../Header/ArticleHeader";
import LoadingComponent from "../loading_component/loading";

const TOSComponent = () => {
  const { Data, loading } = useTOSContext();
  return (
    <Wrapper className={`${loading ? "article loading" : "article"}`}>
      <div className="TOS-article__container">
        {!loading ? (
          Data.map((item, i) => {
            console.log(Object.values(item).filter((item, i) => item));
            const ReviewedData = [
              {
                Category: `${Object.keys(item || "")[i] || ""}`,
                Text: Object.values(item)
                  .filter((item) => item)
                  .map((value, i) => <li className="article-link">{value}</li>),
              },
            ];
            return (
              <div key={i}>
                {ReviewedData[0].Category && (
                  <ArticleHeader theme={ReviewedData[0].Category} />
                )}
                {ReviewedData[0].Text}
              </div>
            );
          })
        ) : (
          <LoadingComponent />
        )}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.article`
  &.loading {
    place-content: center;
  }
`;
export default TOSComponent;

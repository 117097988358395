import React from "react";
import styled from "styled-components";
import { useGalleryContext } from "../../Context/GalleryContext";

const Btns = () => {
  const { tempArr, PostPerPage, paginate } = useGalleryContext();
  const numberOfPages = Math.ceil(tempArr.length / PostPerPage);
  const pageNumbers = [];
  for (let i = 1; i <= numberOfPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <Wrapper>
      <div className="btn-container">
        {pageNumbers.map((number, i) => (
          <button
            key={i}
            onClick={() => paginate(number)}
            className="page-btn-item"
          >
            {number}
          </button>
        ))}
      </div>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  display:grid;
  align-self: flex-end;
  .btn-container {
    margin: 0 auto;
    display: flex;
    align-self: flex-end;

    width: fit-content;
    padding: 0.5rem;
    column-gap: 1rem;
  }
  button {
    background: ${(props) => props.theme.colors.BtnGray};
    color: ${(props) => props.theme.colors.Yellow};

    padding: 0.5rem 1rem;
    border-radius: ${(props) => props.theme.borderRadiusEdgesSmall};
  }
`;
export default Btns;

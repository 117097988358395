import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useGalleryContext } from "../../Context/GalleryContext";
import LoadingComponent from "../loading_component/loading";
import { FaAngleDown } from "react-icons/fa";
import Image from "../../Assets/Images/Image";
import { motion } from "framer-motion";
import LazyLoad from "react-lazy-load";
const GalleryComponent = () => {
  const {
    tempArr,
    loading,
    height,
    btnContainer,
    setBtnContainer,
    newCategories,
    currentImages,
    PostPerPage,
    paginate,
  } = useGalleryContext();
  return (
    <Wrapper>
      {loading ? (
        <div className="loading">
          <LoadingComponent />
        </div>
      ) : (
        <motion.div layout className="gallery-container">
            {tempArr.map(({ Category, ID }, i) => {
            return (
              <motion.div layout key={i}>
                  <Image ID={ID } className="gallery-img"/>
              </motion.div>
            );
          })}
        </motion.div>
      )}
    </Wrapper>
  );
};
const Wrapper = styled.section`
  .gallery-container {
    display: grid;
    place-content: center;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(auto, 300px));
    height: 100%;
    img {
      max-width: 350px;
      object-fit: fill;
      border-radius: ${(props) => props.theme.borderRadiusEdgesSmall};
      align-self: center;
    }
  }
`;
export default GalleryComponent;

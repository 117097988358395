import React from "react";
import styled from "styled-components";
import AboutComponent from "../components/About/AboutComponent";
import Header from "../components/Header/Header";
import { AboutProvider } from "../Context/AboutContext";

const About = () => {
  return (
    <AboutProvider>
      <Wrapper className="page">
        <div className="container">
          <Header theme={"About"} />
          <article className="article">
            <AboutComponent />
          </article>
        </div>
      </Wrapper>
    </AboutProvider>
  );
};
const Wrapper = styled.article`
  .article {
    place-content: center;
  }
`;
export default About;

import "./scss/all.scss";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import styled from "styled-components";
import Navbar from "./components/Navbar/Navbar";
import { Particle } from "./Animations/ParticleJS/Particle";
import Footer from "./components/Footer/Footer";
import { Home, About, Commissions, TOS, All_Artworks } from "./Pages";
const App = () => {
  return (
    <Router>
      <Particle />
      <Wrapper>
        <Navbar Link={Link} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/gallery" element={<All_Artworks />} Link={Link}/>
          <Route path="/commissions" element={<Commissions />} Link={Link}/>
          <Route path="/tos" element={<TOS />} Link={Link}/>
        </Routes>
        <Footer />
      </Wrapper>
    </Router>
  );
};
const Wrapper = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
  gap: 2rem;
`;
export default App;

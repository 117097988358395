import { FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Social_Media_Data = [
  {
    icon: <FaInstagram className="social-media__icon"/>,
  },
];
const Social_Media = () => {
  return (
    <Wrapper className="social-media">
          {Social_Media_Data.map(({ icon }, i) => {
              return <Link key={i}><li className="social-media__icon icon">{icon}</li></Link>;
      })}
    </Wrapper>
  );
};
const Wrapper = styled.section `
    .social-media__icon {
        width: 2rem;
        height: 2rem;
        color: ${(props) => props.theme.colors.black};
      @media screen and (max-width: 320px) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
`
export default Social_Media;

import About from "../../Icons/about.svg";
import Commissions from "../../Icons/Commissions.svg";
import Gallery from "../../Icons/Gallery.svg";
import Home from "../../Icons/home.svg";
import TOS from "../../Icons/TOS.svg";

export const Arr = [
  {
    Icon: Home,
    Title: "Home",
    href: "/",
  },
  {
    Icon: About,
    Title: "About",
    href: "/about",
  },
  {
    Icon: Gallery,
    Title: "Gallery",
    href: "/gallery",
  },
  {
    Icon: Commissions,
    Title: "Commissions",
    href: "/commissions",
  },
  {
    Icon: TOS,
    Title: "TOS",
    href: "/tos",
  },
];

import React from "react";
import ReactLoading from "react-loading";
import styled from "styled-components";
const LoadingComponent = () => {
  return (
    <Wrapper>
      <ReactLoading type="bubbles" color="#F1ECCE" height={"100%"} width={200} />
    </Wrapper>
  );
};
const Wrapper = styled.div `
  width: 100%;
  display: grid;
  place-items: center;
`
export default LoadingComponent;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFeaturedContext } from "../../Context/HomepageFeaturedContext";
import Image from "../../Assets/Images/Image";
import LoadingComponent from "../loading_component/loading";
import { motion } from "framer-motion";
import styled from "styled-components";

const HomeFeaturedImage = () => {
  const { Data, loading } = useFeaturedContext();
  const [width, setWidth] = useState(0);
  const measuredRef = useCallback((node) => {
    if (node !== null || node !== undefined) {
      setWidth(node?.scrollWidth - node?.offsetWidth);
    }
  }, []);

  return (
    <Wrapper>
      {Data && !loading ? (
        <motion.article
          className="carousel"
          ref={measuredRef}
          whileTap={{ cursor: "grabbing" }}
          initial={{ opacity: 0.3 }}
          whileInView={{ opacity: 1 }}
        >
          <motion.div
            drag="x"
            dragConstraints={{
              right: 0,
              left: -width,
            }}
            className="inner-carousel"
          >
            <>
              {Data.map((item, i) => {
                return (
                  <motion.div
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.5 }}
                    key={i}
                    className="item"
                  >
                    {<Image ID={item.ID} className="gallery-img" />}
                  </motion.div>
                );
              })}
            </>
          </motion.div>
        </motion.article>
      ) : (
        <LoadingComponent />
      )}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  max-width: 1200px;
  border-radius: ${(props) => props.theme.borderRadiusEdges};
  margin: 0 auto;
  display: grid;
  gap: 1rem;
  .carousel {
    animation: SlideInLeft 1s ease-in-out;
    cursor: grab;
    /* overflow: hidden; */
    .inner-carousel {
      display: flex;
      gap: 2rem;
      .item {
        min-width: 30rem;
        max-height: 55vh;
        @media screen and (max-width: 550px) {
          min-width: 100%;
          max-height: 100%;
        }
        .gallery-img {
          width: 100%;
          max-height: 55vh;
          height: 100%;
          object-fit: cover;
          max-width: 400px;
          pointer-events: none;
          box-sizing: border-box;
          border-radius: ${(props) => props.theme.borderRadiusEdges};
        }
      }
    }
  }
`;
export default HomeFeaturedImage;
